var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tree-header"},[_c('div',{staticClass:"tree-header__hr",class:{
            'tree-header__hr--hover': _vm.isDragOver
        }},[_c('div',{staticClass:"tree-header__hr-round"})]),_c('div',{staticClass:"tree-header__dropped-zone",style:({ visibility: _vm.isDragged ? 'visible' : 'hidden' }),on:{"drop":function($event){return _vm.onDrop($event, _vm.data, _vm.isIndex)},"dragover":function($event){$event.preventDefault();return _vm.onDragOver($event)},"dragleave":function($event){return _vm.onDragLeave($event)},"dragenter":function($event){$event.preventDefault();}}}),_c('div',{staticClass:"tree-header__expand",style:({
            'visibility': _vm.specialLinks.includes(_vm.data.name) || _vm.data.children && _vm.data.children.length || _vm.data.list && _vm.data.list.length ? 'visible' : 'hidden'
        }),on:{"click":function($event){return _vm.$emit('toggleDropdown')}}},[_c('i',{staticClass:"tree-header__expand-icon"})]),_c('span',{staticClass:"tree-header__link",attrs:{"draggable":_vm.isAdmin},on:{"dragstart":function($event){_vm.startDrag($event, _vm.data, _vm.isIndex)
            _vm.disableBg($event)},"dragover":function($event){$event.preventDefault();return _vm.onDragOverElem($event)},"dragleave":function($event){return _vm.onDragLeaveElem($event)},"dragend":function($event){return _vm.onDragEnd()},"drop":function($event){return _vm.onDrop($event, _vm.data, _vm.isIndex)},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"tree-header__link-background",class:{
                'tree-header__link-background--drop':
                    _vm.isDragOverElem && _vm.data.id && _vm.data.parent
            }}),_c('div',{staticClass:"tree-header__icon",class:{
                'tree-header__icon--root': _vm.specialLinks.includes(_vm.data.name)
            },on:{"click":function($event){$event.stopPropagation();return _vm.openPicker($event)},"mouseover":_vm.disableBg,"mouseleave":_vm.enableBg}},[(_vm.data.emoji)?_c('div',{staticClass:"tree-header__emoji"},[_c('emoji',{attrs:{"emoji":_vm.data.emoji,"set":"apple","size":16}})],1):_c('img',{staticClass:"tree-header__img",attrs:{"src":_vm.imgUrl || _vm.icon}})]),(_vm.data.is_href)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                !_vm.isDragged
                    ? {
                          content: _vm.data.title,
                          placement: 'top',
                          offset: 10
                      }
                    : null
            ),expression:"\n                !isDragged\n                    ? {\n                          content: data.title,\n                          placement: 'top',\n                          offset: 10\n                      }\n                    : null\n            "}],staticClass:"tree-header__title",attrs:{"href":_vm.data.link,"target":"_blank","draggable":"false"}},[_vm._v(_vm._s(_vm.data.title || _vm.data.name))]):_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                !_vm.isDragged
                    ? {
                          content: _vm.data.title,
                          placement: 'top',
                          offset: 10
                      }
                    : null
            ),expression:"\n                !isDragged\n                    ? {\n                          content: data.title,\n                          placement: 'top',\n                          offset: 10\n                      }\n                    : null\n            "}],staticClass:"tree-header__title",attrs:{"tag":"a","to":_vm.data.link,"draggable":"false"}},[_vm._v(_vm._s(_vm.data.title || _vm.data.name))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }