<template>
    <div class="tree-header">
        <div
            class="tree-header__hr"
            :class="{
                'tree-header__hr--hover': isDragOver
            }"
        >
            <div class="tree-header__hr-round" />
        </div>
        <div
            @drop="onDrop($event, data, isIndex)"
            @dragover.prevent="onDragOver($event)"
            @dragleave="onDragLeave($event)"
            @dragenter.prevent
            :style="{ visibility: isDragged ? 'visible' : 'hidden' }"
            class="tree-header__dropped-zone"
        />
        <div
            class="tree-header__expand"
            @click="$emit('toggleDropdown')"
            :style="{
                'visibility': specialLinks.includes(data.name) || data.children && data.children.length || data.list && data.list.length ? 'visible' : 'hidden'
            }"
        >
            <i class="tree-header__expand-icon"/>
        </div>

        <span
            class="tree-header__link"
            :draggable="isAdmin"
            @dragstart="
                startDrag($event, data, isIndex)
                disableBg($event)
            "
            @dragover.prevent="onDragOverElem($event)"
            @dragleave="onDragLeaveElem($event)"
            @dragend="onDragEnd()"
            @drop="onDrop($event, data, isIndex)"
            @dragenter.prevent
        >
            <div
                class="tree-header__link-background"
                :class="{
                    'tree-header__link-background--drop':
                        isDragOverElem && data.id && data.parent
                }"
            ></div>
            <div
                class="tree-header__icon"
                :class="{
                    'tree-header__icon--root': specialLinks.includes(data.name)
                }"
                @click.stop="openPicker"
                @mouseover="disableBg"
                @mouseleave="enableBg"
            >
                <div v-if="data.emoji" class="tree-header__emoji">
                    <emoji
                        :emoji="data.emoji"
                        set="apple"
                        :size="16"
                    />
                </div>
                <img v-else class="tree-header__img" :src="imgUrl || icon"/>
            </div>
            <a
                v-if="data.is_href"
                :href="data.link"
                target="_blank"
                v-tooltip="
                    !isDragged
                        ? {
                              content: data.title,
                              placement: 'top',
                              offset: 10
                          }
                        : null
                "
                draggable="false"
                class="tree-header__title"
            >{{ data.title || data.name }}</a>
            <router-link
                v-else
                tag="a"
                :to="data.link"
                v-tooltip="
                    !isDragged
                        ? {
                              content: data.title,
                              placement: 'top',
                              offset: 10
                          }
                        : null
                "
                draggable="false"
                class="tree-header__title"
            >{{ data.title || data.name }}</router-link>
        </span>
    </div>
</template>

<script>
import session from '@/api/session'
import { mapActions, mapGetters } from 'vuex'

import Material from 'assets/img/favicons/material.svg'
import Rubric from 'assets/img/favicons/rubric.svg'
import { eventBus } from '@/main'
import { Emoji } from 'emoji-mart-vue'

export default {
    name: 'TreeHeaderDoc',
    props: {
        data: {
            type: Object
        },
        isIndex: {
            type: Number
        },
        isMaterial: {
            required: false
        },
        isDragged: {
            type: Boolean
        }
    },
    components: {
        Emoji
    },
    data() {
        return {
            navItemBg: '#494D5A',
            Material,
            Rubric,
            specialLinks: ['База знаний', 'Документы'],
            emojiIcon: this.data.emoji,
            isDragOver: false,
            isDragOverElem: false
        }
    },
    computed: {
        ...mapGetters('default_data', ['current_user', 'isAdmin']),
        ...mapGetters('documents_data', ['documentItems']),

        imgUrl() {
            return this.data.icon ? require('assets/img/favicons/sidebar/' + this.data.icon) : null
        },
        icon() {
            return this.isMaterial ? this.Material : this.Rubric
        }
    },
    methods: {
        ...mapActions('documents_data', ['setDocuments']),

        async fetch(type, array) {
            if (type === 'rubric') {
                await session.post(
                    '/api/v1/rubric_document/drag_and_drop_rubrics/',
                    array
                )
            }

            if (type === 'document') {
                await session.post(
                    '/api/v1/rubric_document/drag_and_drop_documents/',
                    array
                )
            }

            // update document list
            await session.get('/api/v1/rubric_document/')
                .then(response => {
                    const data = response.data
                    this.setDocuments({
                        rubrics: data.rubrics,
                        list: data.list
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        search(data, id) {
            let found = data.find(d => d.id === id)

            if (!found) {
                let i = 0
                while (!found && i < data.length) {
                    if (data[i].children && data[i].children.length) {
                        found = this.search(data[i].children, id)
                    }
                    i++
                }
            }
            return found
        },
        startDrag(event, item, index) {
            this.$emit('change-dragged', true)
            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.effectAllowed = 'move'
            const jsonItem = JSON.stringify(item)
            event.dataTransfer.setData('document', jsonItem)
        },
        async onDrop(event, item, index) {
            const result = { type: '', data: [] }
            const doc = JSON.parse(event.dataTransfer.getData('document'))
            const isChildren = (doc.children && item.id) ? Boolean(this.search(doc.children, item.id)) : false

            let dataOutput = []
            let newParentChildren = []
            let oldParentChildren = []

            // console.log('---------------------------------')
            // console.log('doc', doc)
            // console.log('item', item)
            // console.log('index', index)
            // console.log('Перенесли в др.рубрику', this.isDragOverElem)
            // console.log('documentItems', this.documentItems)

            if (
                item.id &&
                item.id !== doc.id &&
                !isChildren
            ) {
                // rubric
                if (doc.children) {
                    result.type = 'rubric'
                    newParentChildren = await this.changeNewChildren('rubric', item, doc, index)
                    oldParentChildren = await this.changeOldChildren('rubric', doc)

                    // data
                    if (this.isDragOverElem === true) {
                        dataOutput = [
                            {
                                parent_id: doc.parent_id || null,
                                children: oldParentChildren
                            },
                            {
                                parent_id: item.id,
                                children: newParentChildren
                            }
                        ]
                    } else {
                        dataOutput = [
                            {
                                parent_id: doc.parent_id || null,
                                children: oldParentChildren
                            },
                            {
                                parent_id: item.parent_id || null,
                                children: newParentChildren
                            }
                        ]
                    }
                    // document
                } else {
                    result.type = 'document'
                    newParentChildren = await this.changeNewChildren('document', item, doc, index)
                    oldParentChildren = await this.changeOldChildren('document', doc)

                    // data
                    if (this.isDragOverElem === true) {
                        dataOutput = [
                            {
                                rubric_id: doc.parent_id || null,
                                documents: oldParentChildren
                            },
                            {
                                rubric_id: item.id,
                                documents: newParentChildren
                            }
                        ]
                    } else {
                        dataOutput = [
                            {
                                rubric_id: doc.parent_id || null,
                                documents: oldParentChildren
                            },
                            {
                                rubric_id: item.parent_id || null,
                                documents: newParentChildren
                            }
                        ]
                    }
                }

                // console.log('dataOutput', dataOutput)
                // console.log('---------------------------------')

                result.data = dataOutput
                await this.fetch(result.type, result.data)
            }

            this.isDragOver = false
            this.isDragOverElem = false
            this.$emit('change-dragged', false)
        },
        onDragOver() {
            this.isDragOver = true
        },
        onDragLeave() {
            this.isDragOver = false
        },
        onDragOverElem() {
            this.isDragOverElem = true
        },
        onDragLeaveElem() {
            this.isDragOverElem = false
        },
        onDragEnd() {
            this.isDragOver = false
            this.isDragOverElem = false
            this.$emit('change-dragged', false)
        },
        openPicker(e) {
            if (!this.specialLinks.includes(this.data.name) &&
                this.current_user.is_editor_material &&
                !this.data.link.includes('/documents')) {
                this.$nextTick(() => {
                    this.$store.dispatch('sidebar/setPicker', { opened: false })
                    const coords = e.target.getBoundingClientRect()
                    const pickerElem = document.getElementById('sidebar_picker')
                    pickerElem.style.top = `${coords.y + 25}px`
                    pickerElem.style.left = `${coords.x + 50}px`
                    this.$store.dispatch('sidebar/setPicker', {
                        opened: true,
                        id: this.data.id,
                        content_type: this.data.children ? 'rubric' : 'material'
                    })
                })
            }
        },
        disableBg(e) {
            if (e && e.target) {
                const elem = e.target.closest('.tree-header__link') || false

                if (elem !== false) {
                    elem.classList.add('tree-header__link--without-bg')
                }
            }
        },
        enableBg(e) {
            if (e && e.target) {
                const elem = e.target.closest('.tree-header__link') || false

                if (elem !== false) {
                    elem.classList.remove('tree-header__link--without-bg')
                }
            }
        },
        async checkIdRubric(variable) {
            let idRubric = null

            try {
                const resDoc = await session.get(`/api/v1/document/${variable.id}/`)
                idRubric = resDoc.data.rubric
            } catch (error) {
                console.error(error)
            }

            return idRubric
        },
        async changeNewChildren(type, item, el, elNewIndex) {
            let idRubric = null
            let resultArray = []
            const documentItemsArray = { ...this.documentItems }

            elNewIndex = (elNewIndex > 0) ? elNewIndex - 1 : elNewIndex

            // rubric
            if (type === 'rubric') {
                if (this.isDragOverElem === true) {
                    resultArray = item.children ? item.children : []
                } else {
                    idRubric = item.parent_id || null

                    if (idRubric === null) {
                        resultArray = documentItemsArray.rubrics
                    } else {
                        documentItemsArray.rubrics.find((variable) => {
                            if (variable.id === idRubric) {
                                resultArray = variable.children
                            }
                        })
                    }
                }
            }

            // document
            if (type === 'document') {
                if (this.isDragOverElem === true) {
                    resultArray = item.list ? item.list : []
                } else {
                    idRubric = item.parent_id || null

                    if (idRubric === null) {
                        resultArray = documentItemsArray.list
                    } else {
                        documentItemsArray.rubrics.find((variable) => {
                            if (variable.id === idRubric) {
                                resultArray = variable.list
                            }
                        })
                    }
                }
            }

            resultArray = [...resultArray] // костыль, т.к. не совсем понятно, почему vuex до сих-пор жалуется на изменение store
            const elOldIndex = resultArray.findIndex((variable) => variable.id === el.id)

            if (elOldIndex > -1) {
                resultArray.splice(elOldIndex, 1)
            }

            resultArray.splice(elNewIndex, 0, el)
            resultArray = resultArray.map((variable, index) => {
                return {
                    ...variable,
                    position: index
                }
            })

            return resultArray
        },
        async changeOldChildren(type, doc) {
            let resultArray = []
            const documentItemsArray = { ...this.documentItems }
            const idRubric = doc.parent_id || null

            // rubric
            if (type === 'rubric') {
                if (idRubric !== null) {
                    documentItemsArray.rubrics.find((variable) => {
                        if (variable.id === idRubric) {
                            resultArray = variable.children
                        }
                    })
                } else {
                    resultArray = documentItemsArray.rubrics
                }
            }

            // document
            if (type === 'document') {
                if (idRubric !== null) {
                    documentItemsArray.rubrics.find((variable) => {
                        if (variable.id === idRubric) {
                            console.log('variable.list', variable.list)
                            resultArray = variable.list
                        }
                    })
                } else {
                    resultArray = documentItemsArray.list
                }
            }

            const docIndex = resultArray.findIndex((variable) => parseInt(variable.id) === parseInt(doc.id))

            resultArray = [...resultArray] // костыль, т.к. не совсем понятно, почему vuex до сих-пор жалуется на изменение store
            resultArray.splice(docIndex, 1)

            resultArray = resultArray.map((variable, index) => {
                return {
                    ...variable,
                    position: index
                }
            })

            return resultArray
        }
    },
    mounted() {
        eventBus.$on('change-emoji', data => {
            if (data.id === this.data.id) {
                this.data.emoji = data.icon || null
            }
        })
    }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";
.tree-header {
    position: relative;
    display: flex;
    align-items: center;
    &--root {
        .tree-header__link {
            &:hover {
                background: transparent !important;
            }
        }
    }
    &__icon {
        cursor: pointer;
    }
    &__expand {
        cursor: pointer;
        position: absolute;
        z-index: 100;
        left: 10px;
        &::before {
            display: none;
            content: '';
            position: absolute;
            transform: translate(-50%,-50%);
            top: 50%;
            left: 50%;
            height: 20px;
            width: 20px;
            background: #616575;
            border-radius: 2px;
            transition: background $ease;
        }
        &-icon {
            @include icon($sidebar-nav-expand, 10px);
            transform: rotate(0);
            transition: transform $ease;
        }
        &:hover {
            &::before {
                display: block;
            }
        }
    }
    &__link {
        width: 95%;
        padding: 10px 10px 10px 20px;
        display: flex;
        align-items: baseline;
        color: #FFF;
        border-radius: 4px;
        &:not(&--without-bg) {
            &:hover {
                background-color: #616575;
            }
        }
    }
    &__title {
        margin-left: 10px;
        word-break: break-word;
        width: 100%;
        display: flex;
        color: #FFF;
    }
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        &:not(&--root) {
            padding: 3px;
            &:hover {
                background-color: #616575;
                border-radius: 2px;
                transition: background-color $ease;
            }
        }
    }
    &__img {
        $icon-size: 13px;
        width: $icon-size;
        height: $icon-size;
        display: block;
    }
    &__emoji {
        .emoji-mart-emoji {
            display: flex !important;
            font-size: 15px;
            line-height: 15px;
        }
    }
    &__hr {
        position: absolute;
        width: 95%;
        transform: translateY(-50%);
        opacity: 0;
        align-self: center;
        left: 0;
        top: 0;
        margin: 0;
        height: 1px;
        background: #fff;
        transition: opacity 100ms ease, visibility 100ms ease;
        &-round {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            transform: translateY(-50%);
            background: #fff;
        }

        &--hover {
            opacity: 1;
        }
    }
    &__dropped-zone {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        width: 95%;
        height: 30px;
        transform: translateY(-50%);
        visibility: hidden;
        z-index: 1;
    }
}
</style>
